<template>
	<div id="tu-password">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu type="1" />
			</el-col>
			<el-col :span="20">
				<div class="tu-title">
					<div>{{ $t('router.change-password') }}</div>
					<div></div>
				</div>
				<div style="padding: 15px;">
					<el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px" label-position="top">
						<el-form-item :label="$t('auth.login-password')" prop="oldpassword">
							<el-input v-model="form.oldpassword" :placeholder="$t('auth.login-password')" show-password></el-input>
						</el-form-item>
						<el-form-item :label="$t('auth.login-new-password')" prop="password">
							<el-input v-model="form.password" :placeholder="$t('auth.login-new-password')" show-password></el-input>
						</el-form-item>
						<el-form-item :label="$t('auth.login-repeat-password')" prop="password_confirm">
							<el-input v-model="form.password_confirm" :placeholder="$t('auth.login-repeat-password')" show-password></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">{{ $t('Submit') }}</el-button>
							<el-button @click="resetForm('ruleForm')">{{ $t('Reset') }}</el-button>
						</el-form-item>
					</el-form>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import SecondMenu from '@/components/SecondMenu'
import CommonApi from '@/api/common'

export default {
	name: "tu-password",
	components: {
		SecondMenu
	},
	data() {
		return {
			form: {
				oldpassword: '',
				password: '',
				password_confirm: ''
			},
      rules: {
				oldpassword: [
					{
						required: true,
						message: this.$t('auth.Login-password-must-be-filled'),
						trigger: 'blur'
					},
					{
						min: 6,
						max: 16,
						message: this.$t('auth.Login-password-characters'),
						trigger: 'blur'
					}
				],
				password: [
					{
						required: true,
						message: this.$t('auth.Login-new-password-must-be-filled'),
						trigger: 'blur'
					},
					{
						min: 6,
						max: 16,
						message: this.$t('auth.Login-new-password-must-be-filled'),
						trigger: 'blur'
					}
				],
				password_confirm: [
					{
						required: true,
						message: this.$t('auth.Login-new-password-must-be-filled'),
						trigger: 'blur'
					},
					{
						min: 6,
						max: 16,
						message: this.$t('auth.Login-new-password-must-be-filled'),
						trigger: 'blur'
					}
				]
			},
		}
	},
	methods: {
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.form.password == this.form.password_confirm) {
          	CommonApi.uppasswd(this.form).then(res => {
	          	this.$message.success(this.$t('auth.Login-password-update-success'));
	          })
          } else {
          	this.$message.error(this.$t('auth.Login-repeat-password-error'));
          }
        } else {
          this.$message.error(this.$t('auth.Please-enter-right-password'));
          return false
        }
      })
		},
		resetForm(formName) {
			this.$refs[formName].resetFields()
		}
	}
}
</script>
<style lang="less">
#tu-password {
	.tu-title {
		background: var(--el-color-primary);
		padding: 17px 15px;
		color: var(--el-color-white);
		text-align: left;
		font-weight: 700;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.el-form-item {
		.el-form-item__label {
			padding: 0;
			font-size: initial;
			font-weight: 600;
		}
	}
}
</style>